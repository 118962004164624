import {
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  Text,
} from '@chakra-ui/react'
import type { ProductVariant, ShoppingList, ShoppingListLineItem } from '@commercetools/platform-sdk'
import useTranslation from 'next-translate/useTranslation'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { removeFromShoppingList, addToShoppingList } from 'commercetools/api/actions/shopping-list'
import { useShoppingLists } from 'commercetools/hooks/use-shopping-lists'
import { CloseButton } from 'components/elements/CloseButton/CloseButton'
import { ScrollableFlexbox } from 'components/elements/ScrollableFlexbox/ScrollableFlexbox'
import { useToastAlert } from 'hooks/useToastAlert'
import { appInsights } from 'logging/appInsight'
import { arrayHasValue } from 'utils/arrayHasValue'

export type OrderTemplateAddModalProps = Omit<ModalProps, 'children'> & {
  productVariant?: ProductVariant | undefined
  customerId: string
  onCreate?: () => void
}

type FormData = {
  shoppingLists: string[]
}

export const OrderTemplateAddModal = ({
  productVariant,
  customerId,
  isOpen,
  onClose,
  onCreate,
  ...props
}: OrderTemplateAddModalProps): JSX.Element => {
  const { shoppingLists, update } = useShoppingLists()
  const hasShoppingLists = arrayHasValue(shoppingLists.data?.results)

  const { t } = useTranslation('common')
  const toast = useToastAlert()
  const { register, formState, handleSubmit } = useForm<FormData>()

  const getLineItemIdInShoppingListFromProduct = (shoppingList: ShoppingList): string | undefined => {
    return shoppingList.lineItems?.find(
      (lineItem: ShoppingListLineItem) => lineItem.variant?.sku === productVariant?.sku,
    )?.id
  }

  const updateShoppingList = async (data: FormData) => {
    if (productVariant?.sku) {
      const allShoppingLists = shoppingLists.data?.results
      const addedShoppingListsIds = data.shoppingLists
        ? Array.isArray(data.shoppingLists)
          ? data.shoppingLists
          : [data.shoppingLists]
        : []
      const removedShoppingLists = allShoppingLists?.filter(
        (allShoppingList) => !addedShoppingListsIds.includes(allShoppingList?.id),
      )

      await Promise.all([
        removedShoppingLists?.map((removedShoppingList) => {
          const lineItemId = getLineItemIdInShoppingListFromProduct(removedShoppingList)
          if (lineItemId) {
            update(customerId, removedShoppingList.id, removeFromShoppingList(lineItemId))
          }
        }),
        addedShoppingListsIds.map((addedShoppingListsId) =>
          update(customerId, addedShoppingListsId, addToShoppingList(productVariant)),
        ),
      ]).then(() => {
        onClose()
        toast({ title: t('order-templates-products-updated'), status: 'success' })
      })
    } else {
      toast({ title: t('sku-is-missing'), status: 'error' })
    }
  }

  const onSubmit = async (data: FormData) => {
    try {
      updateShoppingList(data)
    } catch (error: any) {
      console.error(error)
      appInsights.trackException({ exception: new Error(error) })
    }
  }

  const isSkuInShoppingList = useCallback(
    (shoppingList: ShoppingList): boolean => {
      if (!arrayHasValue(shoppingList?.lineItems)) {
        return false
      }
      return (
        shoppingList?.lineItems?.some(
          (lineItem: ShoppingListLineItem) => lineItem.variant?.sku && lineItem.variant?.sku === productVariant?.sku,
        ) ?? false
      )
    },
    [productVariant?.sku],
  )

  const getShoppingListIdsWithProduct = useCallback((): string[] => {
    if (!productVariant?.sku || !hasShoppingLists || !shoppingLists) {
      return []
    }

    return (
      shoppingLists?.data?.results
        ?.filter((shoppingList) => isSkuInShoppingList(shoppingList))
        .map((shoppingList) => shoppingList.id) ?? []
    )
  }, [hasShoppingLists, isSkuInShoppingList, productVariant?.sku, shoppingLists])

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered {...props}>
      <ModalOverlay />
      <ModalContent maxW="36rem">
        <HStack w="full">
          <ModalHeader>
            <Text>{t('add-to-order-template')}</Text>
            <CloseButton onClick={onClose} />
          </ModalHeader>
        </HStack>

        <ModalBody>
          <Divider borderBottomWidth={4} borderColor="primary.grey.100" />
          {hasShoppingLists ? (
            <ScrollableFlexbox maxH="360px">
              <Stack py={4} spacing={{ base: 1, sm: 5 }} direction="column">
                <CheckboxGroup defaultValue={getShoppingListIdsWithProduct()}>
                  {shoppingLists?.data?.results?.map((list) => {
                    const maxLineItemCountReached = list?.lineItems && list.lineItems.length > 99
                    return (
                      <Checkbox
                        key={list.id}
                        value={list.id}
                        isDisabled={maxLineItemCountReached}
                        {...register('shoppingLists')}
                      >
                        {list.name.en}
                        {maxLineItemCountReached && (
                          <Text fontSize="xs" position="absolute" right={0} top="50%" transform="translateY(-50%)">
                            {t('limit-items')}
                          </Text>
                        )}
                      </Checkbox>
                    )
                  })}
                </CheckboxGroup>
              </Stack>
            </ScrollableFlexbox>
          ) : (
            <Text my={6}>{t('no-order-template-available')}</Text>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            variant="secondary"
            isLoading={formState.isSubmitting}
            isDisabled={!hasShoppingLists}
            onClick={handleSubmit(onSubmit)}
          >
            {t('save')}
          </Button>

          <Button variant="ghost" color="primary.red" onClick={onCreate}>
            {t('create-new')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
