import { ErrorObject, ProductProjection, ShoppingList, ShoppingListLineItem } from '@commercetools/platform-sdk'
import { useCallback } from 'react'
import { useQuery, UseQueryResult } from 'react-query'

import { fetchShoppingListsById } from 'commercetools/api/shopping-lists'
import { getProductVariant } from 'commercetools/utils/product/getProductVariant'

export type ShoppingListQueryResult = UseQueryResult<ShoppingList, ErrorObject>

type UseShoppingLists = {
  shoppingList: UseQueryResult<ShoppingList, ErrorObject>
  getShoppingListLineItemIfExists: (product: ProductProjection) => ShoppingListLineItem | undefined
}

export function useShoppingListQuery(id: string): ShoppingListQueryResult {
  return useQuery<ShoppingList, ErrorObject>(['shopping-list', id], () => fetchShoppingListsById(id), {
    enabled: Boolean(id),
    retry: false,
  })
}

export function useShoppingList(id: string): UseShoppingLists {
  const shoppingList = useShoppingListQuery(id)

  const getShoppingListLineItemIfExists = useCallback(
    (product: ProductProjection): ShoppingListLineItem | undefined => {
      return shoppingList?.data?.lineItems?.find(
        (lineItem) => lineItem?.variant?.sku === getProductVariant(product)?.sku,
      )
    },
    [shoppingList],
  )

  return { shoppingList, getShoppingListLineItemIfExists }
}
