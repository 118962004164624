import { Alert, AlertDescription, AlertProps, Box } from '@chakra-ui/react'
import { ReactNode } from 'react'

import { InfoIcon } from 'components/elements/Icons/InfoIcon'

type Props = AlertProps & {
  children: ReactNode
  color?: string
  bgColor?: string
  showIcon?: boolean
}

export function ErrorAlert({
  children,
  color = 'primary.black',
  showIcon = false,
  bgColor = 'secondary.beige.100',
  ...props
}: Props) {
  return children ? (
    <Alert backgroundColor={bgColor} color={color} minHeight="16" pb={4} status="error" {...props}>
      {showIcon && <InfoIcon marginRight={4} boxSize={4} />}
      <Box flex="1">
        <AlertDescription display="block">{children}</AlertDescription>
      </Box>
    </Alert>
  ) : null
}
