import { Icon, IconProps } from '@chakra-ui/react'

export function OrderTemplateIcon(props: IconProps) {
  return (
    <Icon width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M23,14 C18.0294373,14 14,18.0294373 14,23 C14,27.9705627 18.0294373,32 23,32 C27.9705627,32 32,27.9705627 32,23 C32,18.0294373 27.9705627,14 23,14 Z M23,16 C26.8659932,16 30,19.1340068 30,23 C30,26.8659932 26.8659932,30 23,30 C19.1340068,30 16,26.8659932 16,23 C16,19.1340068 19.1340068,16 23,16 Z" />
      <path d="M22.9934971,18.5 C23.5325642,18.5 23.9713551,18.9293336 23.9865853,19.4647163 L23.9869942,19.4934971 L23.9869942,22.0130058 L26.5065029,22.0130058 C27.04557,22.0130058 27.4843609,22.4423393 27.4995911,22.9777221 L27.5,23.0065029 C27.5,23.54557 27.0706664,23.9843609 26.5352837,23.9995911 L26.5065029,24 L22.9934971,24 C22.45443,24 22.0156391,23.5706664 22.0004089,23.0352837 L22,23.0065029 L22,19.4934971 C22,18.9448038 22.4448038,18.5 22.9934971,18.5 Z" />
      <path
        d="M19.4934971,22 C20.0325642,22 20.4713551,22.4293336 20.4865853,22.9647163 L20.4869942,22.9934971 L20.4869942,25.5130058 L23.0065029,25.5130058 C23.54557,25.5130058 23.9843609,25.9423393 23.9995911,26.4777221 L24,26.5065029 C24,27.04557 23.5706664,27.4843609 23.0352837,27.4995911 L23.0065029,27.5 L19.4934971,27.5 C18.95443,27.5 18.5156391,27.0706664 18.5004089,26.5352837 L18.5,26.5065029 L18.5,22.9934971 C18.5,22.4448038 18.9448038,22 19.4934971,22 Z"
        transform="translate(21.250000, 24.750000) scale(-1, -1) translate(-21.250000, -24.750000) "
       />
      <path d="M19,8 C19.5522847,8 20,8.44771525 20,9 C20,9.54259554 19.5678563,9.98425851 19.0289692,9.99958845 L19,10 L5,10 C4.44771525,10 4,9.55228475 4,9 C4,8.45740446 4.43214374,8.01574149 4.97103079,8.00041155 L5,8 L19,8 Z" />
      <path d="M13,14 C13.5522847,14 14,14.4477153 14,15 C14,15.5425955 13.5678563,15.9842585 13.0289692,15.9995885 L13,16 L5,16 C4.44771525,16 4,15.5522847 4,15 C4,14.4574045 4.43214374,14.0157415 4.97103079,14.0004115 L5,14 L13,14 Z" />
      <path d="M10,20 C10.5522847,20 11,20.4477153 11,21 C11,21.5425955 10.5678563,21.9842585 10.0289692,21.9995885 L10,22 L5,22 C4.44771525,22 4,21.5522847 4,21 C4,20.4574045 4.43214374,20.0157415 4.97103079,20.0004115 L5,20 L10,20 Z" />
      <path d="M17.172,0 C17.9498391,0 18.6965609,0.302295426 19.2554601,0.841901927 L19.2924401,0.878226552 L23.122,4.70778649 C23.6718999,5.25803895 23.9861497,5.99981324 23.9995529,6.77619188 L24,6.828 L24,10 C24,10.5522847 23.5522847,11 23,11 C22.4574045,11 22.0157415,10.5678563 22.0004115,10.0289692 L22,10 L22,6.828 C22,6.57471256 21.9036918,6.33118946 21.7315079,6.14657797 L21.7075599,6.12177345 L17.8784532,2.29266664 C17.6990821,2.11341046 17.4587727,2.00930853 17.2064741,2.00059505 L17.172,2 L3,2 C2.45740446,2 2.01574149,2.43214374 2.00041155,2.97103079 L2,3 L2,25 C2,25.5425955 2.43214374,25.9842585 2.97103079,25.9995885 L3,26 L10,26 C10.5522847,26 11,26.4477153 11,27 C11,27.5425955 10.5678563,27.9842585 10.0289692,27.9995885 L10,28 L3,28 C1.35971429,28 0.0268942859,26.6835828 0.000401914288,25.0496105 L0,25 L0,3 C0,1.35971429 1.31641715,0.0268942859 2.95038951,0.000401914288 L3,0 L17.172,0 Z" />
    </Icon>
  )
}
