import { Icon, IconProps } from '@chakra-ui/react'

export function OrderTemplateIconChecked(props: IconProps) {
  return (
    <Icon width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(14 14)">
          <circle fill="#39B54A" cx={9} cy={9} r={9} />
          <path
            d="M12.581 4.864a1.066 1.066 0 0 1 1.725 1.253l-.02.026-4.13 5.508a2.135 2.135 0 0 1-3.178.26l-.034-.033-2.132-2.132a1.066 1.066 0 0 1 1.484-1.53l.024.023 2.13 2.13h.003l4.128-5.505Z"
            fill="#FFF"
            fillRule="nonzero"
          />
        </g>
        <g fill="#000" fillRule="nonzero">
          <path d="M19 8a1 1 0 0 1 .029 2H5a1 1 0 0 1-.029-2H19ZM13 14a1 1 0 0 1 .029 2H5a1 1 0 0 1-.029-2H13ZM10 20a1 1 0 0 1 .029 2H5a1 1 0 0 1-.029-2H10Z" />
          <path d="M17.172 0a3 3 0 0 1 2.083.842l.037.036 3.83 3.83A3 3 0 0 1 24 6.776V10a1 1 0 0 1-2 .029V6.828a1 1 0 0 0-.268-.681l-.024-.025-3.83-3.83a1 1 0 0 0-.672-.291L17.172 2H3a1 1 0 0 0-1 .971V25a1 1 0 0 0 .971 1H10a1 1 0 0 1 .029 2H3a3 3 0 0 1-3-2.95V3a3 3 0 0 1 2.95-3H17.172Z" />
        </g>
      </g>
    </Icon>
  )
}
