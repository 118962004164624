import { AlertProps, Flex, useStyleConfig } from '@chakra-ui/react'
import React, { createRef, ReactNode, useEffect } from 'react'

type Props = AlertProps & {
  children?: ReactNode
  maxH: string
}

export function ScrollableFlexbox({ children, maxH }: Props) {
  const ref = createRef<HTMLDivElement>()
  const style = useStyleConfig('ScrollableFlex')
  const [overflowed, setOverflowed] = React.useState(false)

  useEffect(() => {
    if ((ref?.current?.clientHeight || 0) < (ref?.current?.scrollHeight || 0)) {
      setOverflowed(true)
    }
  }, [ref])

  return children ? (
    <Flex ref={ref} pr={overflowed ? 4 : 0} mr={overflowed ? 1 : 0} maxH={maxH} overflowY="auto" sx={style}>
      {children}
    </Flex>
  ) : null
}
