import { Button, IconButton, StyleProps, Text, useDisclosure } from '@chakra-ui/react'
import type { ProductProjection } from '@commercetools/platform-sdk'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useCallback } from 'react'

import { removeFromShoppingList } from 'commercetools/api/actions/shopping-list'
import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'
import { useCustomer } from 'commercetools/hooks/use-customer'
import { useShoppingList } from 'commercetools/hooks/use-shopping-list'
import { useShoppingLists } from 'commercetools/hooks/use-shopping-lists'
import { getProductName } from 'commercetools/utils/product/getProductName'
import { getProductVariant } from 'commercetools/utils/product/getProductVariant'
import { OrderTemplateIcon } from 'components/elements/Icons/OrderTemplateIcon'
import { OrderTemplateIconChecked } from 'components/elements/Icons/OrderTemplateIconChecked'
import { TrashCanIcon } from 'components/elements/Icons/TrashCanIcon'
import { OrderTemplateAddModal } from 'components/modules/OrderTemplate/OrderTemplateAddModal'
import { OrderTemplateCreateModal } from 'components/modules/OrderTemplate/OrderTemplateCreateModal'
import { useToastAlert } from 'hooks/useToastAlert'

const ConfirmationModal = dynamic(
  () => import('components/elements/ConfirmationModal/ConfirmationModal').then((modal) => modal.ConfirmationModal),
  { ssr: false },
)
export type OrderTemplateButtonVariants = 'icon' | 'button' | 'remove'

export type OrderTemplateButtonProps = StyleProps & {
  product: ProductProjection
  variant: OrderTemplateButtonVariants
}

export const OrderTemplateButton = ({ product, variant, ...props }: OrderTemplateButtonProps): JSX.Element => {
  const { t, lang } = useTranslation('common')
  const { query } = useRouter()
  const currentShoppingList = query.id?.toString()
  const toast = useToastAlert()
  const [customer] = useCustomer()

  const { getShoppingListLineItemIfExists, shoppingList } = useShoppingList(query.id?.toString() ?? '')
  const lineItem = getShoppingListLineItemIfExists(product)
  const { shoppingListSkus, update } = useShoppingLists()

  const { erpLegalEntity } = useBusinessUnit()

  const productVariant = getProductVariant(product, erpLegalEntity || '')
  const productName = getProductName(product, lang)

  const deleteLineIteModal = useDisclosure()
  const addLineItemModal = useDisclosure()
  const addCreateOrderTemplateModal = useDisclosure()

  const isAddedToAnyShoppingList = useCallback(
    () => shoppingListSkus.includes(productVariant?.sku),
    [shoppingListSkus, productVariant?.sku],
  )

  const onCreateNewShoppingListHandler = () => {
    addLineItemModal.onClose()
    addCreateOrderTemplateModal.onOpen()
  }

  const updateShoppingList = useCallback(() => {
    if (currentShoppingList && lineItem?.id) {
      update(customer?.data?.id ?? '', currentShoppingList, removeFromShoppingList(lineItem?.id)).then(() => {
        shoppingList.refetch()
        toast({ title: t('product-removed-from-order-template'), status: 'success' })
      })
    } else {
      toast({ title: t('something-goes-wrong'), status: 'error' })
    }
  }, [currentShoppingList, lineItem?.id, update, customer?.data?.id, shoppingList, toast, t])

  const renderOrderTemplateButton = useCallback(() => {
    switch (variant) {
      case 'icon':
        return (
          <IconButton
            borderRadius="2px"
            minW={8}
            h={8}
            variant="ghost"
            aria-label="Add to order template"
            icon={
              isAddedToAnyShoppingList() ? <OrderTemplateIconChecked boxSize={5} /> : <OrderTemplateIcon boxSize={5} />
            }
            onClick={addLineItemModal.onOpen}
            _hover={{ backgroundColor: 'primary.grey.100' }}
          />
        )
      case 'button':
        return (
          <Button
            {...props}
            variant="secondary"
            onClick={addLineItemModal.onOpen}
            rightIcon={<OrderTemplateIcon boxSize={5} />}
          >
            {t('order-template')}
          </Button>
        )
      case 'remove':
        return (
          <IconButton
            borderRadius="2px"
            minW={8}
            h={8}
            variant="ghost"
            aria-label="Remove from order template"
            icon={<TrashCanIcon boxSize={4} />}
            onClick={deleteLineIteModal.onOpen}
            _hover={{ backgroundColor: 'primary.grey.100' }}
          />
        )

      default:
        return null
    }
  }, [variant, props, isAddedToAnyShoppingList, addLineItemModal.onOpen, t, deleteLineIteModal.onOpen])

  return (
    <>
      {renderOrderTemplateButton()}
      <OrderTemplateAddModal
        customerId={customer?.data?.id ?? ''}
        productVariant={productVariant}
        {...addLineItemModal}
        onCreate={onCreateNewShoppingListHandler}
      />

      <OrderTemplateCreateModal
        title={t('add-new-order-template')}
        product={product}
        {...addCreateOrderTemplateModal}
        onClose={addCreateOrderTemplateModal.onClose}
      />
      <ConfirmationModal
        title={t('do-you-want-to-delete-product-from-order-template-title', {
          name: productName,
        })}
        confirmationButtonText={t('order-template-remove-product-confirmation-button-text')}
        cancelButtonText={t('order-template-remove-product-cancel-button-text')}
        onConfirm={updateShoppingList}
        {...deleteLineIteModal}
      >
        <Text>{t('do-you-want-to-delete-product-from-order-template-text')}</Text>
      </ConfirmationModal>
    </>
  )
}
